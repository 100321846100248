<script setup>
import {
  DxColumn,
  DxDataGrid,
  DxPaging,
  DxScrolling,
  DxSearchPanel,
  DxToolbar,
} from 'devextreme-vue/data-grid';
import { DxButton } from 'devextreme-vue/button';
import { DxItem } from 'devextreme-vue/toolbar';
import { getLinkDatasource } from '@/data/link';
import { ROUTE_LINKS } from '@/router/routeNames';
import { useRouter } from 'vue-router';

const router = useRouter();

const linkDatasource = getLinkDatasource();

function onRowClick(e) {
  const linkId = e.data.id;
  router.push(`${ROUTE_LINKS}/${linkId}`);
}

function opretLink() {
  router.push(`${ROUTE_LINKS}/-1`);
}
</script>

<template>
  <DxDataGrid
    :data-source="linkDatasource"
    :remote-operations="true"
    height="100%"
    :focused-row-enabled="true"
    :row-alternation-enabled="true"
    :show-column-lines="false"
    :hover-state-enabled="true"
    :show-borders="true"
    @row-click="onRowClick"
  >
    <DxScrolling mode="virtual" row-rendering-mode="virtual" :preload-enabled="true" />
    <DxPaging :page-size="50" />
    <DxSearchPanel :visible="true" :highlight-search-text="false" placeholder="Søg..." />
    <DxToolbar>
      <DxItem location="before">
        <DxButton
          text="Opret link"
          icon="plus"
          @click="opretLink"
          type="default"
          styling-mode="text"
        />
      </DxItem>
      <DxItem name="searchPanel" location="after" />
    </DxToolbar>
    <DxColumn data-field="navn" />
    <DxColumn data-field="beskrivelse" />
    <DxColumn data-field="linkUrl" />
  </DxDataGrid>
</template>

<style scoped>
.forklaring {
  font-weight: 600;
  font-size: 1.1rem;
  margin-bottom: 10px;
}
</style>
