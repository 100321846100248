<script setup lang="ts">
import { getFilDatasource } from '@/data/fil';
import { getMaalgruppeRolleDatasource } from '@/data/rolle';
import LetButton from '@/letapps-vue/components/LetButton.vue';
import { getAppConfig } from '@/letapps-vue/utils/appConfig';
import type { FilAdgang } from '@/types/filAdgang';
import axios from 'axios';
import { DxButton } from 'devextreme-vue/button';
import { DxFileUploader } from 'devextreme-vue/file-uploader';
import { DxForm, DxLabel, DxSimpleItem } from 'devextreme-vue/form';
import { DxTagBox } from 'devextreme-vue/tag-box';
import { DxItem, DxToolbar } from 'devextreme-vue/toolbar';
import { confirm } from 'devextreme/ui/dialog';
import { ValueChangedEvent } from 'devextreme/ui/file_uploader';
import { ref, watchEffect } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const props = defineProps({
  filId: {
    type: Number,
    required: false,
  },
});

const filDatasource = getFilDatasource();
const maalgruppeRolleDatasource = getMaalgruppeRolleDatasource();

const fil = ref();
const filForm = ref();
const uploader = ref();
const loading = ref(false);
const fileMissing = ref(false);

let fileToUpload: File | undefined = undefined;

async function handleFil(e: ValueChangedEvent) {
  let files = e.value;
  if (files && files.length > 0) {
    fileToUpload = files[0];
    fileMissing.value = false;
    if (!fil.value.navn) fil.value.navn = fileToUpload.name;
    if (!fil.value.filnavn) fil.value.filnavn = fileToUpload.name;
  }
}

async function validerFil() {
  if (!fileToUpload && props.filId === -1) {
    fileMissing.value = true;
  }

  const validation = filForm.value.instance.validate();
  if (fileMissing.value === true) return;

  if (validation.status === 'pending') {
    validation.complete.then(async (asyncValidationResult: any) => {
      if (asyncValidationResult.isValid) await gemFil();
    });
  } else if (validation.isValid) {
    await gemFil();
  }
}

async function gemFil() {
  loading.value = true;
  const formData = new FormData();
  if (fileToUpload) formData.set('files', fileToUpload);
  formData.set('navn', fil.value.navn);
  formData.set('filnavn', fil.value.filnavn);
  if (fil.value.stinavn) {
    formData.set('stinavn', fil.value.stinavn);
  }
  if (props.filId !== -1) {
    formData.set('id', fil.value.id);
  }

  if (fileToUpload) {
    try {
      const response = await axios.post(getAppConfig().apiRoot + '/api/Filer/upload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      fil.value.id = response.data.id;
    } catch (e) {
      if (e instanceof Error) {
        console.error(e.message);
      }
    }
  }
  const newAdgange: FilAdgang[] = [];
  fil.value.maalgruppeRolleIds.forEach((rolleId: number) => {
    const existingAdgang = fil.value.adgange?.find((a: FilAdgang) => a.rolleId === rolleId);
    if (existingAdgang) {
      newAdgange.push(existingAdgang);
    } else {
      newAdgange.push({ rolleId: rolleId, filId: fil.value.id });
    }
  });
  fil.value.adgange = newAdgange;

  try {
    await axios.put(getAppConfig().apiRoot + '/api/Filer/' + fil.value.id, fil.value);
  } catch (e) {
    if (e instanceof Error) {
      console.error(e.message);
    }
  }

  loading.value = false;
  await router.push('/admin/filer');
}

async function sletFil() {
  let result = confirm(
    'Er du sikker at du vil slette <b>' + fil.value.filnavn + '</b>?',
    'Slet fil',
  );
  result.then(async (answer) => {
    if (answer) {
      await filDatasource.store().remove(fil.value.id);
      await router.push('/admin/filer');
    }
  });
}

async function fortryd() {
  if (props.filId && props.filId > 0) fil.value = await filDatasource.store().byKey(props.filId);
  await router.back();
}

watchEffect(async () => {
  if (props.filId && props.filId > 0) {
    fil.value = await filDatasource.store().byKey(props.filId);
  } else {
    fil.value = {};
  }

  if (fil.value && fil.value.adgange) {
    fil.value.maalgruppeRolleIds = fil.value.adgange
      .filter((adgang: FilAdgang) => adgang.rolleId)
      .map((adgang: FilAdgang) => adgang.rolleId);
  } else {
    fil.value.maalgruppeRolleIds = [];
  }
});
</script>

<template>
  <div v-if="fil">
    <div class="container">
      <div class="left">
        <div class="section-header">Filoplysninger</div>
        <form method="post" action="" enctype="multipart/form-data">
          <DxForm ref="filForm" :form-data="fil" :showValidationSummary="true">
            <DxSimpleItem :is-required="true">
              <template #default>
                <DxLabel text="Fil" />
                <DxFileUploader
                  ref="uploader"
                  select-button-text="Vælg fil"
                  upload-mode="useForm"
                  @value-changed="handleFil"
                />
                <div v-if="fil.filnavn" class="existing-file">
                  Eksisterende fil <b>{{ fil.filnavn }}</b> kan overskrives ved at uploade en ny fil
                </div>
              </template>
            </DxSimpleItem>
            <div v-if="fileMissing" class="color-error">Der skal vælges en fil!</div>
            <DxSimpleItem data-field="filnavn" />
            <DxSimpleItem data-field="navn">
              <DxLabel text="Beskrivelse" />
            </DxSimpleItem>
            <!--            <DxSimpleItem data-field="stinavn">-->
            <!--              <DxAsyncRule-->
            <!--                type="async"-->
            <!--                :validation-callback="asyncStinavnValidation"-->
            <!--                message="Stinavn er allered brugt"-->
            <!--              />-->
            <!--            </DxSimpleItem>-->
            <DxSimpleItem data-field="adgange">
              <DxLabel text="Målgruppe" />
              <DxTagBox
                :data-source="maalgruppeRolleDatasource"
                v-model="fil.maalgruppeRolleIds"
                displayExpr="navn"
                valueExpr="id"
              />
            </DxSimpleItem>
            <DxSimpleItem>
              <DxLabel text=" " :showColon="false" />
              <DxToolbar>
                <DxItem location="before">
                  <LetButton
                    :loading="loading"
                    text="Gem fil"
                    :use-submit-behavior="false"
                    @click="validerFil"
                    type="default"
                  />
                </DxItem>
                <DxItem location="before">
                  <DxButton text="Fortryd" @click="fortryd" />
                </DxItem>
                <DxItem location="after">
                  <DxButton
                    text="Slet fil"
                    v-if="filId && filId > 0"
                    @click="sletFil"
                    type="danger"
                    styling-mode="outlined"
                  />
                </DxItem>
              </DxToolbar>
            </DxSimpleItem>
          </DxForm>
        </form>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/css//variables.letguide.scss';

.existing-file {
  color: $letpension-grey;
}

.container {
  display: flex;
  gap: 20px;
}

.left {
  flex: 1;
}

.right {
  width: 500px;
  padding-left: 20px;
  background-color: $letpension-sand-light;
}
</style>
