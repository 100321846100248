<script setup>
import LetCard from '@/letapps-vue/components/LetCard.vue';
import { getAppConfig } from '@/letapps-vue/utils/appConfig';
import axios from 'axios';
import { onMounted, ref } from 'vue';

const ledere = ref({});

onMounted(async () => {
  const response = await axios.get(
    getAppConfig().apiRoot + '/api/brugere/medrolle/' + getAppConfig().lederMaalgruppeId,
  );
  ledere.value = response.data;
});
</script>

<template>
  <LetCard title="Ledere i mit pengeinstitut" class="bg-sand-light">
    <div v-for="leder in ledere" :key="leder.id">
      {{ leder.fornavn }} {{ leder.efternavn }}:
      <a :href="'mailto:' + leder.email">{{ leder.email }}</a>
    </div>
  </LetCard>
</template>

<style scoped></style>
