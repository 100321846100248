<script setup>
import { getAppConfig } from '@/letapps-vue/utils/appConfig';
import LetLink from '../filer/LetLink.vue';
import ImagePdf from '@/images/pdf.png';
import ImageWord from '@/images/word.png';
import ImageExcel from '@/images/excel.png';
import ImagePowerpoint from '@/images/powerpoint.png';

defineProps({
  filListe: { type: Object },
});

function getLink(data) {
  return getAppConfig().apiRoot + '/api/Fil/' + (data.stinavn || data.id);
}

function getMiniature(fil) {
  if (fil.mimeType === 'application/pdf') return ImagePdf;
  if (fil.mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')
    return ImageWord;
  if (fil.mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
    return ImageExcel;
  if (fil.mimeType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation')
    return ImagePowerpoint;
  return ImagePdf;
}
</script>

<template>
  <div v-for="fil in filListe" :key="fil.id" class="opslag-container">
    <div class="opslag-item flex-container">
      <div class="miniature-container">
        <img :src="getMiniature(fil)" class="opslag-miniature" />
      </div>
      <div class="opslag-indhold">
        <div class="opslag-overskrift">
          <a
            v-if="fil.opslagType === 'Eksternt link'"
            class="eksternt-link"
            :href="fil.linkUrl"
            :targer="fil.linkTarget"
            >{{ fil.navn }}</a
          >
          <LetLink v-else :href="getLink(fil)" target="_blank">{{ fil.filnavn }}</LetLink>
        </div>
        <div class="opslag-manchet">
          {{ fil.manchet }}
        </div>
      </div>
      <div class="opslag-arrow"></div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/css//variables.letguide.scss';

.eksternt-link {
  font-weight: 600;
  font-size: 16px;
}

.let-tabs-buttons {
  font-size: 1rem;
}

.let-tab {
  background-color: white;
}

.status-row {
  padding: 2px;
}

.beskeder-link {
  text-align: right;
}

.ingen-opslag {
  padding: 20px;
}

.under-list {
  height: 150px;
  text-align: center;
}

.opslag-container {
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #dddddd;

  &:first-child {
    margin-top: 0;
  }
}

.opslag-item {
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 12px;
  }
}

.opslag-indhold {
  flex: 1;
}

.miniature-container {
  width: 100px;
  height: 57px;
  text-align: center;
  background-color: $letpension-sand;
}

.opslag-miniature {
  width: 100px;
  margin: 0;
}

.opslag-overskrift {
  padding-top: 6px;
  .let-link {
    font-size: 1rem;
    font-weight: 600;
  }
}

.opslag-manchet {
  font-size: 1rem;
  line-height: 1.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: 500;
}

.opslag-arrow {
  width: 16px;
  background-color: $letpension-grey;
  mask-image: url('../../letapps-vue/icons/arrow.svg');
  mask-repeat: no-repeat;
  mask-size: contain;
  mask-position: center left;
  margin-right: 5px;
}
</style>
