<script setup>
import { hasRettighed } from '@/letapps-vue/utils/auth';
import { RettighedsType } from '@/types/rettighed';
import { nextTick, onMounted, ref } from 'vue';

defineProps({
  opslag: {
    type: Object,
    required: true,
  },
  showToc: {
    type: Boolean,
    default: false,
  },
});

const headings = ref([]);
const root = ref(null);

nextTick(() => {
  setTimeout(handleUpdate, 100);
});

onMounted(() => {
  // props.editor.on('update', handleUpdate);
});

function handleUpdate() {
  if (!root.value) return;

  const opslagDoc = root.value.closest('.lg-opslag');
  if (!opslagDoc) return;

  //Find all headers
  const headingsInToc = [];

  const allHeadings = opslagDoc.querySelectorAll(
    'h1,h2,h3,h4, [id="relaterede-materialer-anchor"]',
  );

  allHeadings.forEach((el) => {
    const textWithoutTag = el.innerHTML.replace(/(<([^>]+)>)/gi, '');
    let id = textWithoutTag.replaceAll(' ', '-');

    //Add id to the header
    if (el.getAttribute(id)) {
      id = el.getAttribute(id);
    } else {
      el.setAttribute('id', id);
    }

    //Create link icon
    if (hasRettighed(RettighedsType.LetguideOpslagAdmin)) {
      //check if icon exists
      const check = el.querySelectorAll('.lg-header-link');
      if (check.length === 0) {
        el.classList.add('lg-header');
        const link = document.createElement('a');
        link.setAttribute('class', 'lg-header-link dx-icon-link');
        link.setAttribute('href', '#' + id);
        el.insertAdjacentElement('beforeend', link);
      }
    }

    //Add T3 headers to TOC
    if (el.tagName === 'H3' || el.id === 'relaterede-materialer-anchor') {
      headingsInToc.push({
        level: el.tagName.substr(1),
        text: textWithoutTag,
        id,
      });
    }
  });

  headings.value = headingsInToc;

  setTimeout(() => {
    if (location.hash) {
      var requested_hash = location.hash.slice(1);
      location.hash = '';
      location.hash = requested_hash;
    }
  }, 10);
}
</script>

<template>
  <div ref="root">
    <div class="let-toc" v-if="showToc && headings.length > 0">
      <div class="let-toc__title">Indhold</div>
      <ul class="let-toc__list">
        <li
          class="let-toc__item"
          :class="`let-toc__item--${heading.level}`"
          v-for="(heading, index) in headings"
          :key="index"
        >
          <a :href="`#${heading.id}`" class="let-toc__link">
            {{ heading.text }}&nbsp;<i class="dx-icon-arrowright" />
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped lang="scss">
.let-toc {
  margin-bottom: 30px;
  padding: 0;

  &__title {
    font-size: 1rem;
    font-weight: 600;
  }
  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__link {
    color: black;
    font-size: 1rem;
    text-decoration: none;
  }

  &__item {
    a:hover {
      opacity: 0.5;
    }

    &--4 {
      padding-left: 1rem;
    }

    &--5 {
      padding-left: 2rem;
    }

    &--6 {
      padding-left: 3rem;
    }
  }
}
</style>
