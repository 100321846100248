<script setup>
import OpslagEdit from '@/components/opslag/OpslagEdit.vue';
import { hasRettighed } from '@/letapps-vue/utils/auth';
import { RettighedsType } from '@/types/rettighed';
import OpslagView from '@/components/opslag/OpslagView.vue';
import { useRoute } from 'vue-router';
import { computed } from 'vue';

const route = useRoute();
const viewMode = computed(() => route.params.viewMode);
</script>

<template>
  <OpslagEdit
    :id="route.params.id"
    v-if="viewMode === 'edit' && hasRettighed(RettighedsType.LetguideOpslagAdmin)"
  />
  <OpslagView :id="route.params.id" v-else />
</template>

<style scoped lang="scss"></style>
