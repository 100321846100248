<script setup>
import {
  DxColumn,
  DxDataGrid,
  DxFormItem,
  DxPaging,
  DxScrolling,
  DxSearchPanel,
  DxToolbar,
} from 'devextreme-vue/data-grid';
import { DxButton } from 'devextreme-vue/button';
import { DxItem } from 'devextreme-vue/toolbar';
import { useRouter } from 'vue-router';
import { ROUTE_OPSLAG } from '@/router/routeNames';
import { getOpslagDatasource } from '@/data/opslag';
import { DxCheckBox } from 'devextreme-vue/check-box';
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { useOpslagStore } from '@/stores/opslagStore';

const router = useRouter();
const store = useOpslagStore();

const grid = ref();
const opslagDatasource = getOpslagDatasource();

function onRowClick(e) {
  router.push(`${ROUTE_OPSLAG}/${e.data.stinavn || e.data.masterId}`);
}

async function opretOpslag() {
  try {
    const response = await opslagDatasource.save({
      opslagType: 'Nyhed',
      tagIds: [],
      overskrift: 'Nyt opslag',
      sidebredde: 'normal',
    });
    await router.push(`${ROUTE_OPSLAG}/${response.data.id}/edit`);
  } catch (e) {
    console.error(e.message);
    console.error(e.response.data.Message);
  }
}

function toggleSlettede(e) {
  store.setIncludeDeletedRecords(e.value);
  medtagSlettede(e.value);
}

function medtagSlettede(medtag) {
  if (medtag) {
    opslagDatasource.filter(null);
  } else {
    opslagDatasource.filter('slettet', '=', false);
  }
  grid.value.instance.refresh();
}

onMounted(() => {
  grid.value.instance.state(store.gridState);
  medtagSlettede(store.includeDeletedRecords);
});

onBeforeUnmount(() => {
  store.setGridState(grid.value.instance.state());
});
</script>

<template>
  <DxDataGrid
    ref="grid"
    :data-source="opslagDatasource"
    :remote-operations="true"
    :row-alternation-enabled="true"
    :show-column-lines="false"
    :hover-state-enabled="true"
    :show-borders="true"
    @row-click="onRowClick"
    height="100%"
  >
    <DxScrolling mode="virtual" row-rendering-mode="virtual" :preload-enabled="true" />
    <DxPaging :page-size="10" />
    <DxSearchPanel :visible="true" :highlight-search-text="false" placeholder="Søg i opslag..." />

    <DxToolbar>
      <DxItem location="before">
        <DxButton
          text="Opret opslag"
          icon="plus"
          @click="opretOpslag"
          type="default"
          styling-mode="text"
        />
      </DxItem>
      <DxItem location="after">
        <DxCheckBox
          text="Medtag slettede"
          @value-changed="toggleSlettede"
          :value="store.includeDeletedRecords"
        />
      </DxItem>
      <DxItem name="searchPanel" location="after" />
    </DxToolbar>

    <DxColumn data-field="id" :width="50" />
    <DxColumn data-field="overskrift" />
    <DxColumn data-field="opslagType" caption="Type" :width="100" />
    <DxColumn data-field="kladde" :width="100" />
    <DxColumn data-field="publiceret" :width="100" />
    <DxColumn data-field="altidOeverst" caption="Altid øverst" sort-order="desc" :width="150" />
    <DxColumn
      data-field="publiceringsTidspunkt"
      caption="Publicering"
      data-type="date"
      format="dd-MM-yyyy HH:mm"
      css-class="numbers"
      sort-order="asc"
      :width="150"
    >
      <DxFormItem :visible="false" />
    </DxColumn>
    <DxColumn v-if="store.includeDeletedRecords" data-field="slettet" :width="80" />
  </DxDataGrid>
</template>

<style></style>
