<script setup>
import 'iconify-icon';
import { DxButton } from 'devextreme-vue/button';
import InsertComponentDropDownButton from '@/tiptap/toolbars/InsertComponentDropDownButton.vue';
import LetLinkButton from '@/tiptap/toolbars/LetLinkButton.vue';

const props = defineProps({
  editor: {
    type: Object,
  },
});

function onStyleChange(e) {
  const style = e.target.value;
  if (style === 'paragraph') {
    props.editor.chain().focus().setParagraph().run();
  } else {
    props.editor
      .chain()
      .focus()
      .toggleHeading({ level: Number(style) })
      .run();
  }
}

function onFontSizeChange(e) {
  const fontSize = e.target.value;

  //If no selection is made, we expand to the nearest styled range
  if (props.editor.state.selection.empty) {
    props.editor.commands.extendMarkRange('textStyle');
  }
  if (fontSize) {
    props.editor.chain().focus().setFontSize(fontSize).run();
  } else {
    props.editor.chain().focus().unsetFontSize().run();
  }
  props.editor.chain().removeEmptyTextStyle().run();
}

function onColorChange(e) {
  const color = e.target.value;
  if (props.editor.state.selection.empty) {
    props.editor.commands.extendMarkRange('letSpan');
  }
  if (color) {
    props.editor.commands.setMark('letSpan', { class: 'color-' + color });
  } else {
    props.editor.commands.setMark('letSpan', { class: null });
  }
}
</script>

<template>
  <div class="let-tiptap-toolbar" v-if="editor && editor.isEditable">
    <select @change="onStyleChange" style="width: 150px">
      <option value="paragraph" :selected="editor.isActive('paragraph')">Normal</option>
      <option value="1" :selected="editor.isActive('heading', { level: 1 })">Heading 1</option>
      <option value="2" :selected="editor.isActive('heading', { level: 2 })">Heading 2</option>
      <option value="3" :selected="editor.isActive('heading', { level: 3 })">Heading 3</option>
      <option value="4" :selected="editor.isActive('heading', { level: 4 })">Heading 4</option>
    </select>
    <select @change="onFontSizeChange" style="width: 160px">
      <option value="">Normal størrelse</option>
      <option value="10px" :selected="editor.isActive('textStyle', { fontSize: '10px' })">
        10px
      </option>
      <option value="12px" :selected="editor.isActive('textStyle', { fontSize: '12px' })">
        12px
      </option>
      <option value="14px" :selected="editor.isActive('textStyle', { fontSize: '14px' })">
        14px
      </option>
      <option value="16px" :selected="editor.isActive('textStyle', { fontSize: '16px' })">
        16px
      </option>
      <option value="18px" :selected="editor.isActive('textStyle', { fontSize: '18px' })">
        18px
      </option>
    </select>
    <select @change="onColorChange" style="width: 140px">
      <option value="">Normal farve</option>
      <option value="blue" :selected="editor.isActive('letSpan', { class: 'color-blue' })">
        Blå
      </option>
      <option value="orange" :selected="editor.isActive('letSpan', { class: 'color-orange' })">
        Orange
      </option>
      <option value="green" :selected="editor.isActive('letSpan', { class: 'color-green' })">
        Grøn
      </option>
      <option value="black" :selected="editor.isActive('letSpan', { class: 'color-black' })">
        Sort
      </option>
      <option value="white" :selected="editor.isActive('letSpan', { class: 'color-white' })">
        Hvid
      </option>
    </select>
    <DxButton hint="Fed skrift" icon="bold" @click="editor.chain().focus().toggleBold().run()" />
    <DxButton
      hint="Understreget"
      icon="underline"
      @click="editor.chain().focus().toggleUnderline().run()"
    />
    <DxButton hint="Kursiv" icon="italic" @click="editor.chain().focus().toggleItalic().run()" />
    <DxButton
      hint="Sænket skrift"
      icon="subscript"
      @click="editor.chain().focus().toggleSubscript().run()"
    />
    <DxButton
      hint="Gennemstregning"
      icon="strike"
      @click="editor.chain().focus().toggleStrike().run()"
    />
    <div class="separator"></div>
    <DxButton
      hint="Venstrejusteret"
      icon="alignleft"
      @click="editor.chain().focus().setTextAlign('left').run()"
    />
    <DxButton
      hint="Centreret"
      icon="aligncenter"
      @click="editor.chain().focus().setTextAlign('center').run()"
    />
    <DxButton
      hint="Højrejusteret"
      icon="alignright"
      @click="editor.chain().focus().setTextAlign('right').run()"
    />
    <div class="separator" />
    <DxButton
      hint="Punktliste"
      icon="bulletlist"
      @click="editor.chain().focus().toggleBulletList().run()"
    />
    <DxButton
      hint="Nummereret liste"
      icon="orderedlist"
      @click="editor.chain().focus().toggleOrderedList().run()"
    />
    <div class="separator" />
    <DxButton
      hint="Vandret linie"
      icon="minus"
      @click="editor.chain().focus().setHorizontalRule().run()"
    />
    <LetLinkButton :editor="editor" />
    <div class="separator" />
    <DxButton hint="Undo" icon="undo" @click="editor.chain().undo().run()" />
    <DxButton
      hint="Redo"
      icon="redo"
      @click="editor.chain().redo().run()"
      :disabled="!editor.can().chain().redo().run()"
    />
    <div class="separator" />
    <insert-component-drop-down-button :editor="editor" />
  </div>
</template>

<style scoped lang="scss">
@import '@/css//variables.letguide.scss';
</style>
