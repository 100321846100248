<script setup lang="ts">
import { getLinkDatasource } from '@/data/link';
import LetButton from '@/letapps-vue/components/LetButton.vue';
import { DxSelectBox } from 'devextreme-vue';
import { DxButton } from 'devextreme-vue/button';
import { DxForm, DxLabel, DxSimpleItem } from 'devextreme-vue/form';
import { DxItem, DxToolbar } from 'devextreme-vue/toolbar';
import { confirm } from 'devextreme/ui/dialog';
import { ref, watchEffect } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const props = defineProps({
  linkId: {
    type: Number,
    required: false,
  },
});

const linkDatasource = getLinkDatasource();

const link = ref();
const loading = ref(false);

async function gemLink() {
  loading.value = true;

  try {
    await linkDatasource.save(link.value);
  } catch (e) {
    console.error(e);
  }

  loading.value = false;
  await router.push('/admin/links');
}

async function sletLink() {
  let result = confirm(
    'Er du sikker at du vil slette <b>' + link.value.navn + '</b>?',
    'Slet link',
  );
  result.then(async (answer) => {
    if (answer) {
      await linkDatasource.store().remove(link.value.id);
      await router.push('/admin/links');
    }
  });
}

async function fortryd() {
  if (props.linkId && props.linkId > 0)
    link.value = await linkDatasource.store().byKey(props.linkId);
  await router.back();
}

watchEffect(async () => {
  if (props.linkId && props.linkId > 0) {
    link.value = await linkDatasource.store().byKey(props.linkId);
  } else {
    link.value = {};
  }
});
</script>

<template>
  <div v-if="link">
    <div class="container">
      <div class="left">
        <DxForm :form-data="link" :showValidationSummary="true">
          <DxSimpleItem data-field="navn" />
          <DxSimpleItem data-field="beskrivelse" editorType="dxTextArea" />
          <DxSimpleItem data-field="linkUrl" />
          <DxItem data-field="soegningFaneblad">
            <DxLabel text="Vis i søgeresultat" />
            <DxSelectBox
              v-model="link.soegningFaneblad"
              :items="[
                { value: 'vejledninger', text: 'Vejledninger, Q&A\'s mv.' },
                { value: 'dokumenter', text: 'Dokumenter' },
              ]"
              value-expr="value"
              display-expr="text"
            />
          </DxItem>
          <DxItem data-field="linkType">
            <DxLabel text="Åbnes" />
            <DxSelectBox
              v-model="link.linkTarget"
              :items="[
                { value: '_self', text: 'I samme vindue' },
                { value: '_blank', text: 'I nyt vindue' },
              ]"
              value-expr="value"
              display-expr="text"
            />
          </DxItem>

          <DxSimpleItem>
            <DxLabel text=" " :showColon="false" />
            <DxToolbar>
              <DxItem location="before">
                <LetButton
                  :loading="loading"
                  text="Gem link"
                  :use-submit-behavior="false"
                  @click="gemLink"
                  type="default"
                />
              </DxItem>
              <DxItem location="before">
                <DxButton text="Fortryd" @click="fortryd" />
              </DxItem>
              <DxItem location="after">
                <DxButton
                  text="Slet link"
                  v-if="linkId && linkId > 0"
                  @click="sletLink"
                  type="danger"
                  styling-mode="outlined"
                />
              </DxItem>
            </DxToolbar>
          </DxSimpleItem>
        </DxForm>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/css//variables.letguide.scss';

.container {
  display: flex;
  gap: 20px;
}

.left {
  flex: 1;
  max-width: 800px;
}

.right {
  width: 500px;
  padding-left: 20px;
  background-color: $letpension-sand-light;
}
</style>
