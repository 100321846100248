<script setup lang="ts">
import TiptapComponentWrapper from '@/tiptap/component/TiptapComponentWrapper.vue';
import { NodeViewContent, nodeViewProps, NodeViewWrapper } from '@tiptap/vue-3';
import { DxButton } from 'devextreme-vue/button';
import { onBeforeUnmount, onMounted, onUpdated, ref } from 'vue';
defineProps(nodeViewProps);

const root = ref();

onMounted(() => {
  addEventListener('hashchange', selectTab);
  setTimeout(selectTab, 100);
});
onUpdated(selectTab);

onBeforeUnmount(() => {
  removeEventListener('hashchange', selectTab);
});
function selectTab() {
  const buttons = root.value.querySelectorAll('.let-tab-button');

  let activeTabIsSet = false;
  if (location.hash) {
    const activeTab = location.hash.slice(1);
    buttons.forEach((button: HTMLElement) => {
      const cleanText = button.innerText.toLowerCase().replaceAll(' ', '');
      if (encodeURI(cleanText) === activeTab) {
        activeTabIsSet = true;
        button.click();
        return;
      }
    });
  }

  if (!activeTabIsSet && buttons.length > 0) {
    buttons[0].click();
  }
}
</script>

<template>
  <node-view-wrapper>
    <tiptap-component-wrapper title="Faneblade" :editor="editor">
      <div class="let-tabs" ref="root">
        <node-view-content />
      </div>
      <template #buttons>
        <DxButton icon="trash" hint="Slet alle faneblade" @click="deleteNode" />
      </template>
    </tiptap-component-wrapper>
  </node-view-wrapper>
</template>

<style lang="scss">
@import '@/css//variables.letguide.scss';

.let-tabs {
  margin-top: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid $letpension-grey;
}
</style>
