<script setup>
import { authState, hasRettighed, signOut } from '@/letapps-vue/utils/auth';
import { getAppConfig } from '@/letapps-vue/utils/appConfig';
import { DxButton } from 'devextreme-vue/button';
import LetPage from '@/letapps-vue/components/LetPage';
import BrugerNotifikationer from '@/components/profil/BrugerNotifikationer.vue';
import BrugerAdministratorer from '@/components/profil/BrugerAdministratorer.vue';
import { RettighedsType } from '@/types/rettighed';
import BrugerLedere from '@/components/profil/BrugerLedere.vue';

const appConfig = getAppConfig();
</script>

<template>
  <let-page title="Profil">
    <div class="flex-container">
      <div>
        <div>
          {{ authState.bruger.fornavn }} {{ authState.bruger.efternavn }} ({{
            authState.bruger.email
          }})
        </div>
        <div class="letguide-version">Letguide version: {{ appConfig.buildNumber }}</div>
      </div>
      <DxButton @click="signOut" text="Log ud" />
    </div>

    <div class="flex-container profile-content">
      <BrugerNotifikationer
        class="l-width-500"
        v-if="hasRettighed(RettighedsType.LetguideRaadgiver)"
      />
      <BrugerAdministratorer class="l-width-500" />
      <BrugerLedere class="l-width-500" v-if="hasRettighed(RettighedsType.LetguideFagleder)" />
      <div class="flex-1" v-if="hasRettighed(RettighedsType.DeveloperMode)">
        <h3>Rettigheder</h3>
        <ul>
          <li v-for="rettighed in authState.bruger.rettigheder" :key="rettighed">
            {{ rettighed }}
          </li>
        </ul>
      </div>
    </div>
  </let-page>
</template>

<style scoped lang="scss">
@import '@/css/variables.letguide.scss';

.letguide-version {
  color: $letpension-grey;
}

.profile-content {
  margin-top: 40px;
}
</style>
