<script setup lang="ts">
import { getPengeinstitutDatasource } from '@/data/pengeinstitut';
import { DxSelectBox } from 'devextreme-vue/select-box';
import { Pengeinstitut } from '@/types/pengeinstitut';
import { onMounted, ref } from 'vue';

const sortedItems = ref([]);

const props = defineProps({
  kunAktive: {
    required: false,
    default: false,
  },
});

onMounted(async () => {
  const datasource = getPengeinstitutDatasource();
  await datasource.loadIfEmpty();
  let items = await datasource.items();
  if (props.kunAktive) {
    items = items.filter((x: Pengeinstitut) => !!x.kamId || x.regnr == '0000');
  }
  sortedItems.value = items.sort((p1: Pengeinstitut, p2: Pengeinstitut) =>
    p1.navn > p2.navn ? 1 : p1.navn < p2.navn ? -1 : 0,
  );
});
</script>

<template>
  <DxSelectBox
    :items="sortedItems"
    placeholder="Vælg pengeinstitut"
    displayExpr="navn"
    valueExpr="regnr"
    :width="300"
  />
</template>

<style scoped></style>
