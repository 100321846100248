<script setup lang="ts">
import { hasRettighed } from '@/letapps-vue/utils/auth';
import { useKvartalsrapportStore } from '@/stores/kvartalsrapportStore';
import LetTipTapEditor from '@/tiptap/component/LetTipTapEditor.vue';
import { RettighedsType } from '@/types/rettighed';
import { DxButton } from 'devextreme-vue/button';
import { ref } from 'vue';

defineProps({
  print: {
    type: Boolean,
    default: false,
  },
});
const store = useKvartalsrapportStore();
const editMode = ref(false);

function startEdit() {
  editMode.value = true;
}

async function save() {
  await store.saveRapport();
  editMode.value = false;
}

function cancel() {
  editMode.value = false;
}
</script>

<template>
  <div class="flex-container">
    <div class="titel">Forord</div>
    <DxButton
      v-if="!editMode && !print && hasRettighed(RettighedsType.LetguideOpslagAdmin)"
      text="Redigér"
      @click="startEdit"
    ></DxButton>
    <DxButton v-if="editMode" text="Gem" @click="save"></DxButton>
    <DxButton v-if="editMode" text="Fortryd" @click="cancel"></DxButton>
  </div>
  <div v-if="store.rapport" class="editor kv-forord">
    <LetTipTapEditor v-if="editMode" v-model="store.rapport.forord" :editable="editMode" />
    <LetTipTapEditor v-else :model-value="store.rapport.forord" :editable="false" />
  </div>
</template>

<style scoped lang="scss">
@import '@/css//variables.letguide.scss';

.logo {
  width: 160px;
}

.editor {
  width: 100%;
  height: 600px;
}

.flex-container {
  align-items: center;
}
</style>

<style lang="scss">
.kv-forord .tiptap h2 {
  font-size: 24px;
}
</style>
