<script setup lang="ts">
import { isBestyrelsesmedlem, isLetpensionBruger, isPfaBruger } from '@/letapps-vue/utils/auth';
import RegnrSelectBox from './RegnrSelectBox.vue';
import { getKvartalsrapportDatasource } from '@/data/kvartalsrapport';
import { onMounted, ref } from 'vue';
import KvartalsrapportSelectBox from '@/components/rapportering/KvartalsrapportSelectBox.vue';

interface RapportOptions {
  rapportId: number;
  regnr: string;
}

const model = defineModel<RapportOptions>({ default: { rapportId: 0, regnr: '0' } });

onMounted(async () => {
  const rapporterDs = getKvartalsrapportDatasource();
  await rapporterDs.loadIfEmpty();
  const rapporter = await rapporterDs.items();
  const senesteRapport = rapporter.slice(-1)[0];
  model.value.rapportId = senesteRapport.id;
});
</script>

<template>
  <table v-if="model" class="param-container">
    <tbody>
      <tr>
        <td class="label">Periode:</td>
        <td>
          <KvartalsrapportSelectBox v-model="model.rapportId" />
        </td>
      </tr>
      <tr v-if="isLetpensionBruger() || isPfaBruger() || isBestyrelsesmedlem()">
        <td class="label">Pengeinstitut:</td>
        <td>
          <Suspense>
            <RegnrSelectBox v-model="model.regnr" :width="300" :kunAktive="true" />
          </Suspense>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<style scoped>
.param-container {
  border-spacing: 5px;
}

.label {
  font-weight: bold;
  width: 150px;
}
</style>
