export enum RettighedsType {
  DeveloperMode = 'DeveloperMode',
  AppLetguide = 'AppLetguide',
  LetguideAdmin = 'LetguideAdmin',
  LetguideForside = 'LetguideForside',
  LetguideDriftbesked = 'LetguideDriftbesked',
  LetguideOpslagAdmin = 'LetguideOpslagAdmin',
  LetguideFilAdmin = 'LetguideFilAdmin',
  LetguideTagAdmin = 'LetguideTagAdmin',
  LetguideLinkAdmin = 'LetguideLinkAdmin',
  LetguideAdminDriftstatus = 'LetguideAdminDriftstatus',
  LetguideRapportering = 'LetguideRapportering',
  LetguideRapporteringMenu = 'LetguideRapporteringMenu',
  LetguideKvartalsrapport = 'LetguideKvartalsrapport',
  LetguideRaadgiver = 'LetguideRaadgiver',
  LetguideFagleder = 'LetguideFagleder',
  LetguideKontakt = 'LetguideKontakt',
  LetguideSandkassen = 'LetguideSandkassen',
  AllAuthenticatedUsers = 'AllAuthenticatedUsers',

  AppAdmin = 'AppAdmin',
}
