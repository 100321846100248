<script setup>
import 'iconify-icon';
import { EditorContent, useEditor } from '@tiptap/vue-3';
import StarterKit from '@tiptap/starter-kit';
import TextAlign from '@tiptap/extension-text-align';
import LetImage from '@/tiptap/let-image';
import { LetTable } from '@/tiptap/let-table';
import { LetTableWrapper } from '@/tiptap/let-table/wrapper';
import { TableRow } from '@tiptap/extension-table-row';
import { TrailingNode } from '@/tiptap/trailing-node';
import {
  LetAccordion,
  LetAccordionItem,
  LetAccordionItemContent,
  LetAccordionItemHeader,
} from '@/tiptap/let-accordion';
import { LetSection, LetSectionItem } from '@/tiptap/let-section';
import { FontSize } from 'tiptap-extension-font-size';
import { LetTableCell } from '@/tiptap/let-table-cell';
import { LetTableHeader } from '@/tiptap/let-table-header';
import LetTipTapToolbar from '@/tiptap/component/LetTipTapToolbar.vue';
import { uploadOpslagFil } from '@/utils/opslagUtils';
import { Subscript } from '@tiptap/extension-subscript';
import { LetSpan } from '@/tiptap/let-span';
import { watch } from 'vue';
import { LetTab, LetTabButton, LetTabs, LetTabsButtons } from '@/tiptap/let-tabs';
import { LetLink } from '@/tiptap/let-link';
import { TextStyle } from '@tiptap/extension-text-style';
import { Underline } from '@tiptap/extension-underline';

const props = defineProps({
  opslagId: {
    type: Number,
  },
  modelValue: {
    type: String,
    default: '',
  },
  editable: {
    type: Boolean,
    default: true,
  },
});
const emit = defineEmits(['update:modelValue']);

defineExpose({
  getText: () => editor.value.getText(),
});

async function uploadImage(dataUrl) {
  return await uploadOpslagFil(dataUrl, props.opslagId);
}

const editor = useEditor({
  editable: props.editable,
  enablePasteRules: false,
  enableInputRules: false,

  extensions: [
    Subscript,
    TextAlign.configure({
      types: ['heading', 'paragraph', 'tableCell'],
    }),
    Underline,
    TextStyle,
    LetImage.configure({
      inline: true,
      allowBase64: true,
      uploader: uploadImage,
    }),
    LetTableWrapper,
    LetTable.configure({
      resizable: true,
    }),
    TableRow,
    LetTableHeader,
    LetTableCell,
    LetSpan,
    FontSize,
    LetAccordion,
    LetAccordionItem,
    LetAccordionItemHeader,
    LetAccordionItemContent,
    LetSection,
    LetSectionItem,
    LetTabs,
    LetTab,
    LetTabsButtons,
    LetTabButton,
    LetLink.configure({
      openOnClick: false,
    }),
    StarterKit,
    TrailingNode,
  ],
  content: props.modelValue,

  onUpdate: ({ editor }) => {
    emit('update:modelValue', editor.getHTML());
  },
});

//Make editor content reactive
watch(
  () => props.modelValue,
  (newValue, oldValue) => {
    if (!editor.value.isEditable) {
      if (newValue !== oldValue) {
        editor.value.commands.setContent(newValue, false);
      }
    }
  },
);
</script>

<template>
  <div class="flex-container-column" v-if="editor && editor.isEditable">
    <let-tip-tap-toolbar :editor="editor" />
    <editor-content :editor="editor" class="editor-container" />
  </div>
  <editor-content v-else-if="editor" :editor="editor" />
</template>

<style scoped lang="scss">
@import '@/css//variables.letguide.scss';

.editor-container {
  flex: 1;
  overflow: auto;
}
</style>
