<script setup lang="ts">
import { useKvartalsrapportStore } from '@/stores/kvartalsrapportStore';
import { onMounted, ref, watchEffect } from 'vue';
import { DxItem, DxToolbar } from 'devextreme-vue/toolbar.js';
import DxButton from 'devextreme-vue/button';
import { DxSelectBox } from 'devextreme-vue/select-box.js';
import { useRoute, useRouter } from 'vue-router';
import { ROUTE_KVARTALSRAPPORT_PRINT } from '@/router/routeNames';
import KvartalsrapportSection from './KvartalsrapportSection.vue';
import { useTitle } from '@vueuse/core';
import { ValueChangedEvent } from 'devextreme/ui/select_box';

const router = useRouter();
const route = useRoute();
const title = useTitle();

interface Side {
  text: string;
  value: string;
}

const store = useKvartalsrapportStore();
const regnr = ref();
const rapportId = ref();
const side = ref();
const sider = ref<Side[]>([]);

watchEffect(() => {
  if (store.pengeinstitut) {
    const sideList = [
      { text: 'Forside', value: '/kvartalsrapport/forside' },
      { text: 'Forord', value: '/kvartalsrapport/forord' },
      { text: 'Hovedtal', value: '/kvartalsrapport/hovedtal' },
      { text: 'Letsikring af indtægt ved pension', value: '/kvartalsrapport/livrente' },
      { text: 'Letsikring ved død', value: '/kvartalsrapport/doed' },
      { text: 'Letsikring ved sygdom', value: '/kvartalsrapport/sygdom' },
      { text: 'Rådgiveradfærd', value: '/kvartalsrapport/raadgiveradfaerd' },
    ];

    if (store.pengeinstitut.finansPlus) {
      sideList.push({ text: 'Finans plus', value: '/kvartalsrapport/finansplus' });
    }
    sideList.push({ text: 'Samlet indtjening', value: '/kvartalsrapport/samlet' });
    sider.value = sideList;
  }
});

function onChangeSide(e: ValueChangedEvent) {
  router.push(e.value);
}

onMounted(async () => {
  const rapportIdParam = route.query.rapportId as string;
  rapportId.value = Number.parseInt(rapportIdParam);

  regnr.value = route.query.regnr as string;
  side.value = router.currentRoute.value.path;

  await store.loadData(rapportId.value, regnr.value);
  console.log(
    (title.value =
      store.rapport.aar + '-' + store.rapport.periode + ' ' + store.pengeinstitut.navn),
  );
});

function previousPage() {
  const currentPage = side.value;
  let prevPage = null;
  for (let i in sider.value) {
    const s = sider.value[i];
    if (s.value === currentPage) {
      side.value = prevPage;
      break;
    }
    prevPage = s.value;
  }
}

function nextPage() {
  const currentPage = side.value;
  let selectNext = false;
  for (let i in sider.value) {
    const s = sider.value[i];
    if (selectNext) {
      side.value = s.value;
      break;
    }
    if (s.value === currentPage) {
      selectNext = true;
    }
  }
}

function udskrivRapport() {
  window.open(
    window.location.origin +
      ROUTE_KVARTALSRAPPORT_PRINT +
      '?rapportId=' +
      rapportId.value +
      '&regnr=' +
      regnr.value,
    'kvartalsrapportprint',
  );
}
</script>

<template>
  <div style="padding: 20px 100px">
    <DxToolbar class="let-toolbar" v-if="sider.length > 0">
      <DxItem>
        <DxButton icon="chevronleft" @click="previousPage" :disabled="side === sider[0].value" />
      </DxItem>
      <DxItem>
        <DxSelectBox
          :items="sider"
          v-model="side"
          @valueChanged="onChangeSide"
          placeholder="Vælg side"
          displayExpr="text"
          valueExpr="value"
          :width="300"
        />
      </DxItem>
      <DxItem>
        <DxButton
          icon="chevronright"
          @click="nextPage"
          :disabled="side === sider[sider.length - 1].value"
        />
      </DxItem>
      <DxItem location="after">
        <DxButton icon="print" text="Udskriv" @click="udskrivRapport" />
      </DxItem>
    </DxToolbar>
    <KvartalsrapportSection>
      <router-view />
    </KvartalsrapportSection>
    <div v-for="(e, index) in store.loadErrors" :key="index">{{ e }}</div>
  </div>
</template>

<style scoped lang="scss">
@import '@/css/variables.letguide.scss';

.menu {
  width: 200px;
}
</style>
